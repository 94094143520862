import React from 'react'
import { useDispatch } from 'react-redux'
import { fetchedLoggedInUser, logoutUser } from '../../redux2/authSlice'
import { wishListReducer } from '../../redux2/wishListSlice'

export function Counter() {
  const dispatch = useDispatch()

  return (
    <div>

      <div>
        <button
          aria-label="push"
          onClick={() => dispatch(fetchedLoggedInUser({error: false, message: 'login', data: {id: 1, name: 'tim'}}))}
        >
          Login User
        </button>
        <button
          aria-label="push"
          onClick={() => dispatch(logoutUser())}
        >
          Logout user
        </button>

      </div>

      <div>
        <button
          aria-label="push"
          onClick={() => dispatch(wishListReducer([
            {
                "id": 47,
                "active": 1,
                "slug": "wrist-bank",
                "name": "Wrist Bank",
                "sku": "906846",
                "shortDescription": "",
                "fullDescription": "",
                "approved": 1,
                "defaultImage": "1681726490349wrist_band.jpg",
                "rootImagePath": "https://api.ekki.com.ng/assets/images/uploads/products/default/",
                "defaultVideo": "",
                "rootVideoPath": "",
                "price": 125,
                "currency": "",
                "quantity": 1,
                "rating": null,
                "affiliateLink": null,
                "isAvail": true,
                "discount": "0",
                "new": true,
                "url": null,
                "views": 0,
                "likes": 0,
                "dislikes": 0,
                "soldAt": "2023-04-17T10:14:50.465Z",
                "shop": {
                    "id": 11,
                    "active": 1,
                    "slug": "g-fashion",
                    "name": "G-Fashion",
                    "sku": "801220",
                    "shortDescription": null,
                    "fullDescription": null,
                    "approved": 1,
                    "defaultImage": null,
                    "rootImagePath": null,
                    "defaultVideo": null,
                    "rootVideoPath": null,
                    "numberOfWorkers": 1,
                    "phone": null,
                    "phonePrefix": null,
                    "email": "timothyuijiatalor@yahoo.com",
                    "url": "https://g-fashion.ekki.com.ng",
                    "logo": null,
                    "logoRootPath": null,
                    "views": 17,
                    "likes": 0,
                    "dislikes": 0,
                    "enableSlider": true,
                    "metaData": null,
                    "address": [],
                    "currencies": [],
                    "variation": [
                        {
                            "id": 29,
                            "active": 1,
                            "slug": null,
                            "colour": "red",
                            "size": [
                                {
                                    "id": 78,
                                    "active": 1,
                                    "slug": null,
                                    "name": "x",
                                    "stock": "3"
                                }
                            ]
                        }
                    ],
                    "tag": [
                        {
                            "id": 29,
                            "active": 1,
                            "slug": null,
                            "name": "red"
                        }
                    ],
                    "image": [
                        {
                            "id": 10,
                            "active": 1,
                            "slug": null,
                            "rootPath": null,
                            "path": "/home/gfscom/public_html/ekki/api.ekki.com.ng/assets/images/uploads/shop/default/undefined",
                            "description": ""
                        }
                    ],
                    "video": [],
                    "country": {
                        "id": 158,
                        "active": 1,
                        "slug": "nigeria",
                        "name": "Nigeria",
                        "code": "NG",
                        "dialCode": "+234"
                    }
                },
                "category": [],
                "variation": [
                    {
                        "id": 66,
                        "active": 1,
                        "slug": null,
                        "colour": "red",
                        "size": [
                            [
                                [
                                    [
                                        [
                                            [
                                                [
                                                    [
                                                        [
                                                            [
                                                                [
                                                                    [
                                                                        [
                                                                            {
                                                                                "id": 115,
                                                                                "active": 1,
                                                                                "slug": null,
                                                                                "name": "x",
                                                                                "stock": "3"
                                                                            }
                                                                        ]
                                                                    ]
                                                                ]
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    }
                ],
                "tag": [
                    {
                        "id": 66,
                        "active": 1,
                        "slug": null,
                        "name": "red"
                    }
                ],
                "image": [],
                "video": [],
                "bankAccount": {
                    "id": 19,
                    "active": 1,
                    "slug": "uijiatalor,-timothy-oseremen",
                    "isPrimary": false,
                    "accountNumber": "2149179144",
                    "accountName": "UIJIATALOR, TIMOTHY OSEREMEN",
                    "businessEmail": "timothyuijiatalor@yahoo.com",
                    "businessPhone": "8169522790",
                    "totalCashInflow": 0,
                    "accountType": null,
                    "flutterwaveSubAccountId": "RS_97841B07605D86AA77DB3122B23AE68A",
                    "paystackSubAccountCode": "ACCT_iw4qoxmt00jdhfl",
                    "bank": {
                        "id": 3,
                        "active": 1,
                        "slug": "uba",
                        "name": "United Bank For Africa Plc",
                        "description": "",
                        "code": "",
                        "bankCode": "33154282",
                        "sortCode": "033",
                        "ussdCode": "919",
                        "ibanNumber": null,
                        "logoPath": null,
                        "rootImagePath": null
                    },
                    "currency": {
                        "id": 1,
                        "active": 1,
                        "slug": "ngn",
                        "name": "NGN",
                        "descriptiveName": "Naira",
                        "countryName": null
                    }
                }
            },
            {
                "id": 46,
                "active": 1,
                "slug": "mtn-universal-route-wifi",
                "name": "Mtn universal route/ wifi",
                "sku": "849916",
                "shortDescription": "4 G network very fast",
                "fullDescription": "",
                "approved": 1,
                "defaultImage": "168133139341620230330_115144.jpg",
                "rootImagePath": "https://api.ekki.com.ng/assets/images/uploads/products/default/",
                "defaultVideo": "",
                "rootVideoPath": "",
                "price": 19000,
                "currency": "",
                "quantity": 20,
                "rating": null,
                "affiliateLink": null,
                "isAvail": true,
                "discount": "0",
                "new": true,
                "url": null,
                "views": 0,
                "likes": 0,
                "dislikes": 0,
                "soldAt": "2023-04-12T20:29:53.476Z",
                "shop": {
                    "id": 10,
                    "active": 1,
                    "slug": "distinction-tech",
                    "name": "Distinction Tech",
                    "sku": "607555",
                    "shortDescription": null,
                    "fullDescription": null,
                    "approved": 1,
                    "defaultImage": null,
                    "rootImagePath": null,
                    "defaultVideo": null,
                    "rootVideoPath": null,
                    "numberOfWorkers": 1,
                    "phone": null,
                    "phonePrefix": null,
                    "email": "elemonfolooseghale@gmail.com",
                    "url": "https://distinction-tech.ekki.com.ng",
                    "logo": null,
                    "logoRootPath": null,
                    "views": 27,
                    "likes": 0,
                    "dislikes": 0,
                    "enableSlider": true,
                    "metaData": null,
                    "address": [],
                    "currencies": [],
                    "variation": [
                        {
                            "id": 20,
                            "active": 1,
                            "slug": null,
                            "colour": "red",
                            "size": [
                                {
                                    "id": 20,
                                    "active": 1,
                                    "slug": null,
                                    "name": "x",
                                    "stock": "3"
                                }
                            ]
                        }
                    ],
                    "tag": [
                        {
                            "id": 20,
                            "active": 1,
                            "slug": null,
                            "name": "red"
                        }
                    ],
                    "image": [
                        {
                            "id": 9,
                            "active": 1,
                            "slug": null,
                            "rootPath": null,
                            "path": "/home/gfscom/public_html/ekki/api.ekki.com.ng/assets/images/uploads/shop/default/undefined",
                            "description": ""
                        }
                    ],
                    "video": [],
                    "country": {
                        "id": 158,
                        "active": 1,
                        "slug": "nigeria",
                        "name": "Nigeria",
                        "code": "NG",
                        "dialCode": "+234"
                    }
                },
                "category": [],
                "variation": [
                    {
                        "id": 65,
                        "active": 1,
                        "slug": null,
                        "colour": "red",
                        "size": [
                            [
                                [
                                    [
                                        [
                                            [
                                                [
                                                    [
                                                        [
                                                            [
                                                                [
                                                                    [
                                                                        [
                                                                            {
                                                                                "id": 114,
                                                                                "active": 1,
                                                                                "slug": null,
                                                                                "name": "x",
                                                                                "stock": "3"
                                                                            }
                                                                        ]
                                                                    ]
                                                                ]
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    }
                ],
                "tag": [
                    {
                        "id": 65,
                        "active": 1,
                        "slug": null,
                        "name": "red"
                    }
                ],
                "image": [],
                "video": [],
                "bankAccount": {
                    "id": 8,
                    "active": 1,
                    "slug": "oseghale---elemonfolo",
                    "isPrimary": false,
                    "accountNumber": "2085433429",
                    "accountName": "OSEGHALE   ELEMONFOLO",
                    "businessEmail": "elemonfolooseghale@gmail.com",
                    "businessPhone": null,
                    "totalCashInflow": 0,
                    "accountType": null,
                    "flutterwaveSubAccountId": null,
                    "paystackSubAccountCode": "ACCT_wnxwlgf7amzahb2",
                    "bank": {
                        "id": 7,
                        "active": 1,
                        "slug": "zenith",
                        "name": "Zenith Bank Plc",
                        "description": "",
                        "code": "",
                        "bankCode": "57150013",
                        "sortCode": "057",
                        "ussdCode": "966",
                        "ibanNumber": null,
                        "logoPath": null,
                        "rootImagePath": null
                    },
                    "currency": {
                        "id": 1,
                        "active": 1,
                        "slug": "ngn",
                        "name": "NGN",
                        "descriptiveName": "Naira",
                        "countryName": null
                    }
                }
            }
        ]))}
        >
          Add WishList
        </button>
        <button
          aria-label="push"
          onClick={() => dispatch(logoutUser())}
        >
          Remove Wish List
        </button>
        <button
          aria-label="push"
          onClick={() => dispatch(logoutUser())}
        >
          Remove All Wish List
        </button>

      </div>


    </div>
  )
}