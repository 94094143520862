import { createSlice } from '@reduxjs/toolkit'

export const SLIDER_SLICE_NAME = 'slider';
const initialState = [];
  
export const sliderSlice = createSlice({
  name: SLIDER_SLICE_NAME,
  initialState,
  reducers: {
    sliderReducer: (state = initialState, action) => {
        return [
            ...state, 
            ...action.payload
        ]
    }
  },
})

// Action creators are generated for each case reducer function
export const { sliderReducer } = sliderSlice.actions

export default sliderSlice.reducer